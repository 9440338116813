<template>
  <a-layout-footer class="footer">
    MyBlog ©2022 Created by Jie
  </a-layout-footer>
</template>

<style scoped>
  .footer {
    text-align: center;
  }
</style>