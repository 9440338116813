<template>
  <a-layout-header class="container">
    <div>
      <img src="../../assets/logo.jpg" height="50" width="100" @click="()=>{this.$router.push({path:'/'})}" />
    </div>
    <div class="title">
      <span style="font-size: 22px;"  @click="()=>{this.$router.push({path:'/'})}"><home-outlined :style="{ fontSize: '30px'}" />首页</span>
      <div class="wbIcon" ><span class="wbIconTitle" @click="goWrite"><highlight-outlined />写博客</span></div>
      <div class="search">
        <a-input-search placeholder="搜索" />
      </div>
    </div>
    <div class="account" v-if="!isLogin()">
      <span class="login"><a @click="goLogin" style="color:#969696">登陆</a></span>
      <a-divider type="vertical" />
      <span class="registerBtn"><a @click="goLogin" style="color: #ec7259;">注册</a></span>
    </div>
    <div class="l-account" v-if="isLogin()">
      <a-dropdown @visibleChange="dropChange">
        <MyAvatar />
        <template #overlay>
          <a-menu style="margin-top: -15px; width: 120px;">
            <a-menu-item class="menuItem" @click="goPersonspace">
              <user-outlined style="margin-right: 8px;" />
              <span>我的主页</span>
              <right-outlined style="margin-left: 4px; font-size: 10px;" />
            </a-menu-item>
            <a-menu-item class="menuItem" @click="goPersonsetting">
              <setting-outlined style="margin-right: 8px;" />
              <span>设置</span>
              <right-outlined style="margin-left: 4px; font-size: 10px;" />
            </a-menu-item>
            <a-menu-item key="msg" class="menuItem" @click="goMyMsg">
              <bell-outlined style="margin-right: 8px;" />
              <a-badge :count="userMsgsNumStore.getTotal"
              :overflow-count="99">
                <span >我的消息</span>
              </a-badge>
              <right-outlined style="margin-left: 4px; font-size: 10px;" />
            </a-menu-item>
            <a-menu-item key="logout" class="menuItem" @click="logout">
              <poweroff-outlined style="margin-right: 8px;" />
              <span>点击注销</span>
              <right-outlined style="margin-left: 4px; font-size: 10px;" />
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
  </a-layout-header>
</template>
<script>
  import { HomeOutlined, HighlightOutlined, UserOutlined, SettingOutlined, BellOutlined, PoweroffOutlined, RightOutlined } from '@ant-design/icons-vue';
  import { isLogin, getUserId, uptUserMsgStore } from '../../utils/account.js';
  import { useUserMsgStore } from '../../store/modules/userMsgs.js';
  import MyAvatar from '../common/MyAvatar.vue';
  import { defineComponent, reactive, ref } from 'vue';
  import { message } from 'ant-design-vue';
  import router from '../../router';
  export default defineComponent({
    name: 'Header',
    components: {
      HomeOutlined,
      HighlightOutlined,
      MyAvatar,
      UserOutlined,
      SettingOutlined,
      BellOutlined,
      PoweroffOutlined,
      RightOutlined
    },
    setup() {
      const userMsgsNumStore = useUserMsgStore()
      const goPersonspace = () => {
        router.push({
          path: `/space/${getUserId()}`,
        })
      }
      const goPersonsetting = () => {
        router.push({
          path: `/setting/${getUserId()}`,
        })
      }
      const goMyMsg = () => {
        router.push({
          path: `/msg/${getUserId()}`,
        })
      }
      const logout = () => {
        router.push({
          path: '/login'
        })
        message.success('注销成功')
        localStorage.removeItem('userToken')
      }
      const goWrite = () => {
        router.push({
          path: '/writeBlog'
        })
      }
      const goLogin = () => {
        router.push({
          path: '/login'
        })
      }
    
      const dropChange = (visiable)=> {
        // console.log('visiable', visiable)
        if(visiable){
          uptUserMsgStore()
        }
      }
      return {
        isLogin,
        goPersonspace,
        goPersonsetting,
        goMyMsg,
        logout,
        goWrite,
        goLogin,
        dropChange,
        userMsgsNumStore
      }
    },
  })
</script>
<style scoped>
  .container {
    background-color: aliceblue;
    text-align: center;
    height: 60px;
    display: flex;
  }
  .title {
    margin-left: 200px;
    line-height: 60px;
    color: #333;
    display: flex;
  }
  .wbIcon {
    /* float: right; */
    height: 40px;
    width: 100px;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    line-height: 40px;
    margin-top: 10px;
    margin-left: 20px;
    border-radius: 20px;
    font-size: 15px;
    color: #fff;
    background-color: #ea6f5a;
  }
  .search {
    display: flex;
    align-items: center;
    margin-left: 16px;
    height: 60px;
    border-radius: 12px;
  }
  .account {
    height: 60px;
    font-size: 16px;
    margin-left: 300px;
  }
  .l-account {
    height: 60px;
    width: 160px;
    margin-left: 300px;
  }
  .registerBtn {
    padding: 8px 16px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 20px;
    background-color: #fff;
    border:1px solid #c06cee;
  }
  .menuItem:hover {
    background-color: #969696;
    border-radius: 6px;
  }
</style>