<template>
  <a-layout>
    <a-layout-header style = "background-color: aliceblue;" >
      <Header />
    </a-layout-header>
    <a-layout :style="{ padding: '24px 0px', textAlign: 'center',  minHeight: '100%' }">
      <a-layout-content style="text-align: center;">
        <router-view></router-view>
      </a-layout-content>
    </a-layout>
    <a-layout-footer> <Footer /></a-layout-footer>
  </a-layout>
</template>
<script>
  import Header from '../header/Header.vue';
  import Footer from '../common/Footer.vue';
  export default {
    name: 'Home',
    components: {
    Header,
    Footer,
}
  }
</script>